import React from "react";
import { Link } from "gatsby";
import ogPic from "../../../images/ogp.png";

import { TagLink } from "../../01_atoms/TagLink";

import styles from "./style.module.scss";

type OuterProps = {
  post: {};
  href: string;
};

export const DescItem: React.FC<OuterProps> = (props) => {
  const { post, href } = props;

  return (
    <div className={styles.descItem}>
      <Link to={href} className={styles.descItemContent}>
        {post.frontmatter.ogpimage === "" ||
        post.frontmatter.ogpimage === null ? (
          <div className={styles.descItemImageArea}>
            <img
              className={styles.descItemImage}
              src={ogPic}
              alt="honmushi blog"
            />
          </div>
        ) : (
          <div className={styles.descItemImageArea}>
            <img
              className={styles.descItemImage}
              src={"/asset/ogp/" + post.frontmatter.ogpimage}
              alt={post.frontmatter.title}
            />
          </div>
        )}
        <span className={styles.descItemDate}>{post.frontmatter.date}</span>
        <h3 className={styles.descItemTitle}>{post.frontmatter.title}</h3>
      </Link>
      <div className={styles.descItemTags}>
        {post.frontmatter.tags.map((value, id) => (
          <TagLink key={id} id={id} text={value} />
        ))}
      </div>
    </div>
  );
};
