import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { kebabCase } from "lodash";

import Layout from "../layout";
import { AdsenseComponent } from "../02_molecules/adsense";
import { Header } from "../03_organisms/header";
import { Footer } from "../03_organisms/footer";
import { Sidebar } from "../03_organisms/sidebar";
import { DescItem } from "../02_molecules/descItem";
import { Tags } from "../02_molecules/tags";
import ogPic from "../../images/ogp.png";

import styles from "./tags-style.module.scss";

const TagPage = ({ pageContext, data }) => {
  const { tag, numPages, currentPage } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${tag} に関する記事 ${currentPage} / ${numPages}ページ （${totalCount}件）`;

  const pageUrl =
    currentPage === 1
      ? data.site.siteMetadata.siteUrl + "/tags/" + kebabCase(tag) + "/"
      : data.site.siteMetadata.siteUrl +
        "/tags/" +
        kebabCase(tag) +
        "/" +
        currentPage +
        "/";

  //  console.log(data)
  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        title={tagHeader + " | " + data.site.siteMetadata.title}
        meta={[
          {
            name: "description",
            content: tagHeader + " | " + data.site.siteMetadata.description,
          },
          {
            name: "keywords",
            content: tag + " " + data.site.siteMetadata.keywords,
          },
          {
            property: "og:title",
            content: tagHeader + " | " + data.site.siteMetadata.title,
          },
          {
            property: "og:description",
            content: tagHeader + " | " + data.site.siteMetadata.description,
          },
          { property: "og:url", content: pageUrl },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: data.site.siteMetadata.title },
          { property: "twitter:card", content: "summary_large_image" },
          {
            property: "twitter:site",
            content: data.site.siteMetadata.twitterUsername,
          },
          {
            property: "google-site-verification",
            content: data.site.siteMetadata.google_site_verification,
          },
          {
            property: "og:image",
            content: data.site.siteMetadata.siteUrl + ogPic,
          },
        ]}>
        <script
          async
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <div className="mainWrapper">
        <Header />
        <div id="main-contents">
          <h1>{tagHeader}</h1>
          <div id="flex-root">
            {edges.map(({ node }, index) => (
              <DescItem post={node} href={node.fields.slug} key={index} />
            ))}
          </div>
          <div className={styles.pagenation}>
            {Array.from({ length: numPages }).map((_, i) => {
              if (i + 1 === currentPage) {
                return (
                  <span className={styles.pagenationTextDisable}>{i + 1}</span>
                );
              }
              if (i === 0) {
                return (
                  <a
                    className={styles.pagenationText}
                    href={"/tags/" + kebabCase(tag) + "/"}>
                    {i + 1}
                  </a>
                );
              } else {
                return (
                  <a
                    className={styles.pagenationText}
                    href={"/tags/" + kebabCase(tag) + "/" + (i + 1) + "/"}>
                    {i + 1}
                  </a>
                );
              }
            })}
          </div>
          <AdsenseComponent
            client="ca-pub-8068277400499023"
            slot="7208126827"
          />
        </div>
        <Sidebar />
        <Tags />
        <Footer />
      </div>
    </Layout>
  );
};

TagPage.propTypes = {
  pathContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default TagPage;

export const pageQuery = graphql`
  query($tag: String, $skip: Int, $limit: Int) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD")
            tags
            ogpimage
          }
          fields {
            slug
          }
          excerpt(truncate: true)
        }
      }
    }
    site: site {
      siteMetadata {
        title
        keywords
        description
        siteUrl
        twitterUsername
        google_site_verification
      }
    }
  }
`;
