import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { TagLink } from "../../01_atoms/TagLink";

import styles from "./style.module.scss";

export const Tags = () => (
  <StaticQuery
    query={graphql`
      query {
        tags: allMarkdownRemark(limit: 2000) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `}
    render={(data) => (
      <div className={styles.tagsArea}>
        <div className={styles.tagsTitle}>tags</div>
        <div className={styles.tag}>
          {data.tags.group.map((value, id) => (
            <TagLink key={id} id={id} text={value["fieldValue"]} />
          ))}
        </div>
      </div>
    )}
  />
);
